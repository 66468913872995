<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('dae_subsidy.subsidy_amount_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_division.division') }}
                    </template>
                    <v-select name="division_id"
                      v-model="search.division_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= divisionList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district_id"
                    >
                    <template v-slot:label>
                        {{ $t('org_pro_district.district') }}
                    </template>
                    <v-select name="district_id"
                        v-model="search.district_id"
                        label="text"
                        :reduce="item => item.value"
                        :options=districtList
                        :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" >
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazilla_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla') }}
                    </template>
                    <v-select name="district_id"
                      v-model="search.upazilla_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= upazilaList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="circular_id"
                    >
                    <template v-slot:label>
                      {{ $t('dae_subsidy.subsidy_circular') }}
                    </template>
                    <v-select name="circular_id"
                      v-model="search.circular_id"
                      label="text"
                      :reduce="item => item.value"
                      :options= circularList
                      :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="8">
                  <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('dae_subsidy.subsidy_amount_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                            <i class="fas fa-plus"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                          <!-- <export-excel
                            class="btn btn-success mr-2"
                            :data="excelData"
                            :title="headerValue"
                            worksheet="Report Sheet"
                            :default-value="headerExcelDefault"
                            name="subsidy-amount-report">
                            {{ $t('globalTrans.export_excel') }}
                          </export-excel> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('dae_subsidy.subsidy_amount_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                <b-row>
                                  <b-col md="3"><strong>{{ $t('org_pro_division.division') }}: </strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchResult.division_name : searchResult.division_name_bn) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="3"><strong>{{ $t('org_pro_district.district') }}: </strong>{{ search.district_id  ?  ($i18n.locale === 'en' ? searchResult.district_name : searchResult.district_name_bn) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="3"><strong>{{ $t('org_pro_upazilla.upazilla') }}: </strong>{{ search.upazilla_id  ?  ($i18n.locale === 'en' ? searchResult.upazilla_name : searchResult.upazilla_name_bn) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="3"><strong>{{ $t('dae_subsidy.subsidy_circular') }}: </strong>{{ search.circular_id  ?  ($i18n.locale === 'en' ? searchResult.circular_name : searchResult.circular_name_bn) : $t('globalTrans.all') }}</b-col>
                                </b-row>
                                <hr>
                              </div>
                                <template v-if="datas.length">
                                  <b-row v-for="(item, parentIndex) in datas" :key="parentIndex">
                                    <b-col>
                                      <h4>{{ $t('dae_subsidy.upazilla_name') }} : {{ $i18n.locale === 'en' ? item.upazilla_name : item.upazilla_name_bn }}</h4>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                        <b-thead>
                                          <b-tr>
                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.nid') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.instrument') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.brand_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.model') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.distribution_data') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.actual_price') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.subsidized_price') }}</b-th>
                                            <b-th class="text-center">{{ $t('dae_subsidy.farmer_paid') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in item.upazilla_info" :key="index">
                                          <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name }}</b-td>
                                          <b-td class="text-center">{{ $n(info.nid_no, {useGrouping: false})}}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale === 'en' ? '0' : '০') }}{{ $n(info.mobile_no, {useGrouping: false})}}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.instrument_name_bn : info.instrument_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.brand_name_bn : info.brand_name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.model : info.model }}</b-td>
                                          <b-td class="text-center">{{ (info.distribution_date )}}</b-td>
                                          <b-td class="text-center">{{ $n(info.actual_price, {useGrouping: false})}}</b-td>
                                          <b-td class="text-center">{{ $n(info.subsidized_price, {useGrouping: false})}}</b-td>
                                          <b-td class="text-center">{{ $n(info.farmer_paid, {useGrouping: false})}}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyAmountReportApi } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
    },
    data () {
        return {
          search: {
            division_id: 0,
            district_id: 0,
            upazilla_id: 0,
            circular_id: 0
          },
          searchResult: {
            division_name: '',
            division_name_bn: '',
            district_name: '',
            district_name_bn: '',
            upazilla_name: '',
            upazilla_name_bn: '',
            circular_name: '',
            circular_name_bn: ''
          },
          formData: {
            from_date: '',
            to_date: '',
            status: ''
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false,
          districtList: [],
          upazilaList: [],
          unionList: []
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.headerDataExcel()
    },
    computed: {
        divisionList: function () {
          return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
            headerVal[0] = this.headerExcelDefault.orgName
            headerVal[1] = this.headerExcelDefault.address
            headerVal[2] = this.$t('dae_reports.subsidy_wise_allocation_report')
            headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name
          } else {
            headerVal[0] = this.headerExcelDefault.orgNameBn
            headerVal[1] = this.headerExcelDefault.address_bn
            headerVal[2] = this.$t('dae_reports.subsidy_wise_allocation_report')
            headerVal[3] = this.$t('elearning_config.fiscal_year') + ':  ' + this.searchResult.fiscal_year_bn + '; ' + ' ' + this.$t('dae_config.season_name') + ': ' + this.searchResult.season_name_bn
          }
          return headerVal
        },
        excelData: function () {
            const listData = this.datas
            var serial = 0
            const listContractor = listData.map(keyItem => {
            serial++
                if (this.$i18n.locale === 'en') {
                return {
                    'sl ': serial,
                    'Farmer Name': keyItem.name,
                    'NID ': this.$n(keyItem.nid_no),
                    'Mobile Number': keyItem.mobile_no,
                    'Instrument Name': keyItem.instrument_name,
                    'Brand Name ': keyItem.brand_name,
                    'Model ': keyItem.model,
                    'Distribution Date': keyItem.distrubution_date,
                    'Actual Price': this.$n(keyItem.actual_price),
                    'Subsidized Price': this.$n(keyItem.subsidized_price),
                    'Farmer Paid': this.$n(keyItem.farmer_paid)
                }
                } else {
                return {
                    'ক্রমিক সংখ্যা': this.$n(serial),
                    'কৃষকের নাম': keyItem.name_bn,
                    'এনআইডি ': this.$n(keyItem.nid_no),
                    'মোবাইল নম্বর': this.$n(keyItem.mobile_no),
                    'যন্ত্রের নাম ': keyItem.instrument_name_bn,
                    'পরিচিতিমুলক নাম ': keyItem.brand_name_bn,
                    'মডেল ': keyItem.model,
                    'বিতরণের তারিখ ': keyItem.distrubution_date,
                    'প্রকৃত মূল্য ': this.$n(keyItem.actual_price),
                    'ভর্তুকিযুক্ত মূল্য': this.$n(keyItem.subsidized_price),
                    'কৃষক প্রদত্ত ': this.$n(keyItem.farmer_paid)
                }
                }
            })
            return listContractor
        },
        circularList: function () {
          return this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === 1)
        }
    },
    watch: {
      'search.division_id': function (newValue, OldValue) {
        this.districtList = this.getDistrictList(newValue)
      },
       'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
      },
      'search.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      }
    },
    methods: {
        getDistrictList (divisonId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisonId) {
                return districtList.filter(district => district.division_id === divisonId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUnionList (upazillaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazillaId) {
                return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        headerDataExcel () {
            RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/2').then(response => {
            if (response.success) {
                const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 2)
                const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
                const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
                this.headerExcelDefault.orgName = orgName
                this.headerExcelDefault.orgNameBn = orgNameBn
                this.headerExcelDefault.address = response.data.address
                this.headerExcelDefault.address_bn = response.data.address_bn
            }
            })
        },
        pdfExport () {
            const reportTitle = this.$t('dae_subsidy.subsidy_amount_report')
            ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.searchResult)
        },
        async searchData () {
        this.loading = true
        this.showData = true
        const params = Object.assign({}, this.formData)
            await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyAmountReportApi, params).then(response => {
            if (response.success) {
                if (response.data) {
                if (this.search.division_id) {
                    const divisionObj = this.$store.state.commonObj.districtList.find(division => division.value === this.search.division_id)
                    this.searchResult.division_name = divisionObj !== undefined ? divisionObj.text_en : ''
                    this.searchResult.division_name_bn = divisionObj !== undefined ? divisionObj.text_bn : ''
                }
                if (this.search.district_id) {
                    const districtObj = this.$store.state.commonObj.districtList.find(district => district.value === this.search.district_id)
                    this.searchResult.district_name = districtObj !== undefined ? districtObj.text_en : ''
                    this.searchResult.district_name_bn = districtObj !== undefined ? districtObj.text_bn : ''
                }
                if (this.search.upazilla_id) {
                    const upazillaObj = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === this.search.upazilla_id)
                    this.searchResult.upazilla_name = upazillaObj !== undefined ? upazillaObj.text_en : ''
                    this.searchResult.upazilla_name_bn = upazillaObj !== undefined ? upazillaObj.text_bn : ''
                }
                if (this.search.circular_id) {
                    const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circular => circular.value === this.search.circular_id)
                    this.searchResult.circular_name = circularObj !== undefined ? circularObj.text_en : ''
                    this.searchResult.circular_name_bn = circularObj !== undefined ? circularObj.text_bn : ''
                }
                this.datas = this.relationData(response.data)
                }
            } else {
                this.datas = []
            }
            })
            this.loading = false
        },
        relationData (data) {
          const upazillaStore = this.$store.state.commonObj.upazilaList
          const instrumentStore = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
          const listData = data.map(item => {
            const upazillaObject = upazillaStore.find(upazillaItem => upazillaItem.value === item.upazilla_id)

            const upazillaData = {
              upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : ' ',
              upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ' '
            }
            item.upazilla_info = item.upazilla_info.map(instrumentInfoData => {
              const instrumentObj = instrumentStore.find(instrument => instrument.value === instrumentInfoData.instrument_id)
              const instrumentData = {
                instrument_name: instrumentObj !== undefined ? instrumentObj.text_en : ' ',
                instrument_name_bn: instrumentObj !== undefined ? instrumentObj.text_bn : ' '
              }
              return Object.assign({}, instrumentInfoData, instrumentData)
            })

            return Object.assign({}, item, upazillaData)
          })

          return listData
        },
        getStatus (status) {
            if (status === 2) {
                return this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved'
            } else {
            return this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject'
            }
        }
    }
}
</script>
