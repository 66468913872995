// Project Wise Subsidy Mapping Api Routes...
export const preparationApproveList = 'dae/grant-allocation-distribution/demand-collection/list'

// Subsidy Stock Management Api Routes...
export const subsidyStockManagementList = 'dae/subsidy/stock/list'
export const subsidyStockStore = 'dae/subsidy/stock/store'
export const subsidyStockUpdate = 'dae/subsidy/stock/update'
export const subsidyStockCircularInstrument = 'dae/subsidy/stock/circular-instrument'
export const subsidyStockCircularCrops = 'dae/subsidy/stock/circular-crops'
export const subsidyStockCircularMat = 'dae/subsidy/stock/circular-materials'
// stock new
export const subsidyStockNewManagementList = 'dae/subsidy/stock-new/list'
export const subsidyStockNewStore = 'dae/subsidy/stock-new/store'
export const subsidyStockDetails = 'dae/subsidy/stock-new/details'
// circular routes
const circular = 'dae/subsidy/circular/'

export const circularList = circular + 'list'
export const circularStore = circular + 'store'
export const circularUpdate = circular + 'update'
export const circularToggleStatus = circular + 'toggle-status'
export const circularDestroy = circular + 'destroy'
export const circularDataApi = 'dae/grant-allocation-distribution/demand-collection/circular-data'

// Application Api Routes
const application = '/dae/subsidy/application/'
export const applocationAll = application + 'all'
export const applocationVerifyList = application + 'verification-list'
export const applocationList = application + 'list'
export const applicationShow = application + 'show'
export const applicationStore = application + 'store'
export const applicationUpdate = application + 'update'
export const applicationStatus = application + 'toggle-status'
export const applicationDestroy = application + 'destroy'
export const applicationRequestList = application + 'request-list'
export const applicationStatusUpdate = application + 'status-update'
export const applicationSortingForward = application + 'sorting-forward'
export const applicationForward = application + 'forward'
export const applicationSingleUpazillaAppDetails = application + 'single-upazilla-application-details'
export const applicationApproveAll = application + 'approve-all'
export const applicationForwardedComments = application + 'forwarded-comments'

// Distribution
const distribution = 'dae/subsidy/distribution/'
export const getApplicationDataApi = distribution + 'application'
export const getStockTotalQuantityAvailableApi = distribution + 'getstocktotalquantityavailable'
export const getApplicationInfo = distribution + 'application-info'
export const getFarmerMobileNidApi = distribution + 'get-farmer-mobile-nid-api'
export const getDistApplicantApi = distribution + 'get-dist-applicant-list'
export const getCircularInfo = distribution + 'circular-name'
export const distributionList = distribution + 'list'
export const distributionStore = distribution + 'store'
export const distributionPendingList = distribution + 'pending-list'
export const distributedList = distribution + 'distributed-list'
export const subSidySingleDistriPendingList = distribution + 'single-distribution-pending'
export const subSidySingleDistriDetails = distribution + 'single-distribution-pending-details'
export const subSidySingleDistribute = distribution + 'single-distribute'

const payroll = 'dae/subsidy/payroll/'
export const getUpazillaPayrollFarmer = payroll + 'upazilla-distribution'
export const upazillaPrepare = payroll + 'upazilla-prepare'
export const upazillaApprovedPayroll = payroll + 'list'
export const getPayrollFarmer = payroll + 'payroll-farmer'
export const districtPrepare = payroll + 'district-prepare'

export const BillPrepareList = 'dae/subsidy/bill-prepare/list'
export const BillPrepareStore = 'dae/subsidy/bill-prepare/store'
export const BillPrepareUpdate = 'dae/subsidy/bill-prepare/update'
export const BillPrepareForward = 'dae/subsidy/bill-prepare/forward'
export const BillPrepareApprovedList = 'dae/subsidy/bill-prepare/approve-list'
export const BillPrepareStatusUpdate = 'dae/subsidy/bill-prepare/status-update'
export const designationWiseUser = '/dae/subsidy/bill-prepare/get-user'
export const approveFarmerUpazila = '/dae/subsidy/bill-prepare/application-list'
// Subsidy Report Routes
export const subsidyAmountReportApi = 'dae/subsidy/report/amount-report'
export const farmerReport = '/dae/subsidy/report/farmer-report'
export const distributionReport = '/dae/subsidy/report/distribution-report'
